@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}

.font-header {
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    color: #2C2A2B;
    font-size: 32px;
    text-transform: none;
}

.container {
    max-width: 1200px;
    /* Adjust the max-width as needed */
    margin: 0 auto;
    padding: 0 20px;
    /* Optional: Add padding for some spacing */
}

.home-jc {
    background: #008CD3 50% no-repeat;
    background-image: linear-gradient(to bottom, rgb(10, 12, 70), rgba(10, 20, 70, 0.5) 90%), url('../src/assets/jc-background.png') !important;
    background-position-x: center !important;
    background-size: cover !important;
    height: 100vh;
    filter: brightness(1.4) contrast(1.1);
}

.nav-jc {
    background: rgb(10, 12, 70) 50% no-repeat;
    background-image: linear-gradient(to top, rgb(10, 12, 70), rgba(10, 20, 70, 0.5) 90%);
    background-position-x: center;
    background-size: cover;
    filter: brightness(1.4) contrast(1.1);
}

.about-jc {
    background: #008CD3 100% no-repeat;
    /* background-image: linear-gradient(to top, #0a0a5a, rgba(8, 54, 238, 0.05)), url(../src/assets/Jurski.jpg); */
    background-size: 50% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.skills-jc {
    background: #008CD3 100% no-repeat;
    /* background-image: linear-gradient(to bottom, rgba(10, 10, 106) 95%, #1240C6); */
    background-size: 100% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.contact-jc {
    background: #008CD3 100% no-repeat;
    /* background-image: linear-gradient(to top, rgba(10, 10, 106) 95%, #1240C6); */
    background-size: 100% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient( to right, #008CD3, hsla(242, 74%, 61%, 0.8))!important;
}

@media screen and (max-width: 640px) {
    .about-pj {
        background-image: unset;
    }
}